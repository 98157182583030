import React, { useEffect } from "react"
import { graphql } from "gatsby"

import Layout from "../layout"
import SEO from "../seo"

function Template({
data, // this prop will be injected by the GraphQL query below.
}) {
    const { markdownRemark } = data; // data.markdownRemark holds your post data
    const { frontmatter, html } = markdownRemark;

    useEffect(() => {
        window.scrollTo(0, 0);
    })
    
    return (<Layout>
        <SEO title="Portfolio" />
        
        <div className="row main-content portfolio-page" id="mainContent">
        <div className="row main-content__box">
        
        <div className="columns twelve">
            <div className="portfolio__content">
                <div className="portfolio__image" style={{backgroundImage: 'url(' + frontmatter.image + ')'}} title={frontmatter.title} alt={frontmatter.title} />
                <h4>{frontmatter.title}</h4>
                <div className="container">
                <h6>Used in this project:</h6>
                <div className="portfolio__content__tech-used">{frontmatter.tech.map((item,i) => <span className="portfolio__content__tech-used__item" key={i}>{item}</span> )}</div>
                
                <div className="portfolio__content__main" dangerouslySetInnerHTML={{ __html: html }} />
                
            </div>
            
            </div> 
            </div>
            </div>
            </div>
        
        </Layout>)
        
}

export const singlePortfolioPageQuery = graphql`
    query($path: String!) {
        markdownRemark(frontmatter: {path: {eq: $path}}) {
        frontmatter {
        image
        path
        title
        tech
        }
        html
    }
    }`;

export default Template